<template>
  <b-modal
    id="modal-export"
    :title="this.i18nT(`Export table`)"
    :ok-title="i18nT(`Confirm`)"
    no-close-on-backdrop
    @ok="onSave"
    @hidden="resetForm"
    @shown="getConfiguration"
    size="lg"
  >
    <b-row class="my-1 justify-content-center">
      <b-col cols="12" md="9">
        <b-form-group>
          <b-row>
            <b-col cols="6" v-for="(col, index) in configColumns" :key="'export-' + index">
              <b-form-checkbox
                v-model="col.isExportable"
                inline
                class="rt-custom-checkbox ml-1 mt-1"
                :disabled="col.disabled"
              >
                <div class="d-flex">
                  <span class="mr-1">{{ i18nT(col.header) }}</span>
                </div>
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-form-group>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>

import {
  BCol,
  BFormGroup,
  BForm,
  BFormCheckbox,
  BModal,
  BCard,
  BRow,
} from 'bootstrap-vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";



export default {
    components: {
        BFormCheckbox,
        BRow,
        BCol,
        BForm,
        BCard,
        BFormGroup,
        BModal,
    },
    props: {
        module: {
            type: String,
            required: true
        },
        exportModule: {
            type: String,
            default() { 
                return this.module; 
            }
        },
        allFilters: {
            type: Object,
            required: true
        }
    },
    data(){
        return {
            configColumns: {},
            controlConfig: ''
        }
    },
	created(){
        this.getConfiguration()
    },
    methods:{
        getConfiguration(){
            this.$http.get(`system/exportConfiguration?key=${this.module}`,{showLoader: false})
                .then(({data}) => {
                    console.log(data)
                    this.configColumns = data.data.config[this.module]
                    this.controlConfig = JSON.stringify(this.configColumns)
                })
                .catch(error => {
                    console.log(error);
                });
        },
        onSave(event) {
            event.preventDefault();

            if(this.controlConfig === JSON.stringify(this.configColumns)) {
                console.log('they are the same')
                this.export()
            }
            else {
                console.log('they are not the same')
                this.saveConfing()
            }
          
        },
        saveConfing() {

            const obj = {
                [this.module]: {
                    ...this.configColumns
                }
            }

            const formData = new FormData()
            formData.append('key', this.module)
            formData.append('config', JSON.stringify(obj))

            this.$http.post('system/exportConfiguration', formData)
            .then(data => {
               this.export()
            }).catch(ex => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: this.errorMessage(ex),
                        icon: 'InfoIcon',
                        variant: 'danger'
                    }
                })
            })
        },
        export() {
            const params = this.allFilters

            this.$http.get(this.exportModule, {params})
            .then(({data}) => {
                const url = URL.createObjectURL(new Blob([data], { type: 'text/csv'}));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${this.exportModule}.csv`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                this.$bvModal.hide('modal-export');
            })
            .catch(error => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: this.errorMessage(error),
                        icon: 'InfoIcon',
                        variant: 'danger'
                    }
                })
            })
        },
        resetForm() {
            return
        },
    },
}

</script>